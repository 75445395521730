<template>
  <div>
    <div class="logo"><img src="../assets/logo.png" alt="" /></div>

    <div class="content-info">
      <div class="background-img">
        <img src="../assets/back_img1.png" alt="" v-if="showReport" />
        <img src="../assets/back_img.png" alt="" v-else />
      </div>
      <div class="info-box" v-if="!showReport">
        <div class="left-img">
          <img src="../assets/info.png" alt="" @click.stop="showReportEvent" />
        </div>

        <div class="right-list">
          <ul v-if="reportList.length > 0">
            <div v-for="(item, index) in reportList" :key="index">
              <li v-if="index < 3" @click="getDetail(item.ID)">
                <div>
                  <span>{{ item.title }}</span>
                  <span>{{ item.time }}</span>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </div>

      <!-- 公告信息 -->
      <div class="report-box" v-else>
        <div class="report-box-top">
          <div><span class="line"></span>公告信息</div>
          <img
            src="../assets/del.png"
            alt=""
            @click="showReport = !showReport"
          />
        </div>
        <ul v-if="reportList.length > 0">
          <li
            v-for="(item, index) in reportList"
            :key="index"
            @click="getDetail(item.ID)"
          >
            <div>
              <span>{{ item.title }}</span>
              <span>{{ item.time }}</span>
            </div>
          </li>
        </ul>
        <div class="pagination">
          <el-pagination
            background
            prev-text="上一页"
            next-text="下一页"
            layout="prev, pager, next"
            :total="pageInfo.totalCount"
            :current-page="pageInfo.currentPage"
            :current-change="changeEvent"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 登录 -->
      <div class="login-box">
        <img src="../assets/welcome.png" alt="" class="loginTopImg" />

        <div class="input-item">
          <img src="../assets/usernameIcon.png" alt="" />
          <span class="title">身份证：</span>
          <el-input
            type="text"
            v-model="idCard"
            placeholder="请输入身份证号"
            clearable
            @blur="checkIdCard"
          ></el-input>
          <div v-if="isIdCardPass">
            <span class="tip">您输入的身份证号格式有问题</span>
          </div>
        </div>

        <div class="input-item">
          <img src="../assets/passwordIcon.png" alt="" />
          <span class="title">手机号：</span>
          <el-input
            type="text"
            v-model="mobile"
            placeholder="输入手机号接收随机码"
            clearable
            @blur="checkPhone"
          ></el-input>
          <div>
            <span v-if="isMobilePass" class="tip"
              >您输入的手机号格式有问题</span
            >
          </div>
        </div>
        <div class="input-item">
          <img src="../assets/code.png" alt="" />
          <span class="title">验证码：</span>
          <el-input
            type="text"
            @blur="checkCode"
            v-model="code"
            placeholder="请输入验证码"
            clearable
          ></el-input>
          <img @click="changeCode" :src="path" alt="" class="input-code-img" />
          <div>
            <span v-if="isCodePass" class="tip">您输入的验证码格式有问题</span>
          </div>
        </div>
        <div class="input-item">
          <img src="../assets/passwordIcon.png" alt="" />
          <span class="title">随机码：</span>
          <el-input
            type="text"
            v-model="verificationCode"
            placeholder="请输入6位手机随机码"
            @blur="checkVerificationCode"
            clearable
          ></el-input>
          <el-button
            type="primary"
            :disabled="isDisabled"
            @click="getVerificationCode"
            ><span class="time" v-if="timeShow">{{ timing }}秒后重新获取</span
            ><span v-if="!timeShow">获取手机随机码</span></el-button
          >
          <div>
            <span v-if="isVerificationCode" class="tip"
              >您输入的随机码格式有问题</span
            >
          </div>
        </div>
        <el-button class="login-button" @click="toLogin">登录</el-button>
        <div class="agree-box">
          <el-checkbox
            v-model="ischeck"
            @click.stop="agreeEvent"
            size="mini"
            text-color="#303031"
            >我同意</el-checkbox
          >
          <span
            @click.stop="dialogVisibleAgreement = !dialogVisibleAgreement"
            class="agree"
            >《网上缴费综合服务平台使用协议》</span
          >
        </div>
      </div>
    </div>
    <div class="footer-box">
      <p>Copyright © 2022-2025 成都市职工大学 版权所有：成都市职工大学</p>
      <p>网站备案号：蜀ICP备2022019795号</p>
    </div>

    <el-dialog
      :title="reportInfo.title"
      :visible.sync="dialogVisibleAgreement"
      width="50%"
      center
    >
      <div class="prototype-content">
        <h1 style="text-align: center">协议内容</h1>
        <div class="content">
          <p>
            您在使用本网站时，即表示您充分理解和同意我们按照本《隐私保护条款》的全部内容。
          </p>
          一、信息收集<br />
          （一）本缴费系统遵循正当、合法、必要的原则，出于本《隐私保护条款》所属的以下目的，收集和使用您在使用本缴费系统过程中主动提供或者因使用服务而产生的信息。如果我们要将您的信息用于本《隐私保护条款》未载明的其他用途，或与其他特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前征得您的同意。我们提供服务时，可能会收集、存储和使用与您有关的信息。如果您不提供相关信息，可能无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。<br />
          （二）当您访问使用本缴费系统时，即可访问本缴费系统并获取信息。在使用本缴费系统上部分功能时，本缴费系统需收集您的信息<br />
          <p>
            1.向本缴费系统申请缴费时，您需要在本缴费系统核实姓名、联系电话、身份证证件号码、电子邮箱等信息。
          </p>
          <p>
            2.使用本缴费系统时，您需要调用微信或支付宝进行费用支付，并可以获取成都市职工大学开具的非税缴款发票，包括下载、打印等以及浏览公告信息。
          </p>
          （三）当您登陆缴费系统时，您需要向本缴费系统提供您的身份证号码、手机号码、短信验证码等信息，同时同意本缴费系统通过验证您的身份证信息的方式对有关信息进行有效性核验。<br />
          （四）当您登录本缴费系统时，您需要向本缴费系统提供您的身份证号码+手机号码+短信验证码登录，同时同意本缴费系统对您提供的登录信息进行有效性核验，如您不提供上述信息，本缴费系统将无法向您提供缴费功能<br />
          二、信息使用<br />
          （一）您向本缴费系统提供的个人信息，本缴费系统将利用技术手段对您的信息进行去标识化、加密或匿名处理，并将可能用于下列用途：<br />
          <p>1.核实用户身份，并提供相应的服务；</p>
          <p>2.以该用户名义提出的查询内容；</p>
          <p>3.用于编制有关缴费系统的统计数据；</p>
          <p>4.通过发送短信、电子邮件或以其他方式，告知用户相关信息；</p>
          （二）在未得到您同意前，本缴费系统不会将用户的任何个人信息提供给任何第三方，但发生以下事由的除外：<br />
          <p>1.与国家安全、国防安全直接相关的；</p>
          <p>2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
          <p>3.有犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
          <p>
            4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
          </p>
          <p>5.收集的个人信息是您自行向社会公众公开的；</p>
          <p>
            6.合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
          </p>
          <p>7.法律法规规定、监管要求即行业主管部门有关规定的义务相关的；</p>
          <p>8.为维护本网站的所有权及相关权利的。</p>
          三、信息共享、转让和公开披露<br />
          （一）共享<br />
          <p>
            本缴费系统不会与本缴费系统以外的公司、组织和个人共享您的个人信息，但以下情况除外：
          </p>
          <p>1.事先获得您的明示同意。</p>
          <p>
            2.在法定情形下的共享：本缴费系统可能会根据法律法规规定、监管要求、诉讼争议解决需求，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
          </p>
          （二）转让<br />
          <p>
            本缴费系统不会将您的个人信息转让给任何公司、组织机构和个人。但以下情况除外：
          </p>
          <p>1.事先获得您的明示同意。</p>
          <p>
            2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供。
          </p>
          （三）本缴费系统仅会在以下情况下，公开披露您的个人信息：<br />
          <p>
            1.获得您的明示同意或基于您的主动选择，本缴费系统可能会公开披露您的个人信息；
          </p>
          <p>
            2.如果本缴费系统确定您出现违反法律法规或严重违反本缴费系统相关协议规则的情况，或为保护本缴费系统或公众的人身财产安全免遭侵害，本缴费系统可能依据法律法规或本缴费系统相关协议规则公开披露您的个人信息，包括相关违规行为以及本缴费系统已对您采取的措施。
          </p>
          （四）共享、转让、公开披露个人信息时事先征得授权同意的例外<br />
          <p>
            以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
          </p>
          <p>1.与国家安全、国防安全直接相关的；</p>
          <p>2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
          <p>3.有犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
          <p>
            4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
          </p>
          <p>5.收集的个人信息是您自行向社会公众公开的；</p>
          <p>
            6.合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
          </p>
          <p>7.法律法规规定、监管要求即行业主管部门有关规定的义务相关的；</p>
          <p>8.为维护本网站的所有权及相关权利的。</p>
          四、信息保存<br />
          本缴费系统会按照法律法规规定、监管要求，将收集的个人信息存储于中华人民共和国境内。<br />
          您在使用本缴费系统期间，本缴费系统将持续为您保存您的个人信息。在您注销本缴费系统账户后，本缴费系统仅在法律法规规定、监管要求的期限内，以及为实现本《隐私保护条款》声明的目的所必须的时限内保留您的个人信息。超出上述个人信息保存期限后，本缴费系统会对您的个人信息进行删除或匿名化处理。<br />
          五、信息安全<br />
          本缴费系统努力为您的个人信息安全提供保障,以防止信息的丢失、不当使用、未经授权访问或公开披露。<br />
          (一)为了保障您的信息安全,本缴费系统将在法律法规相关规定要求和现有技术水平下使用符合业界标准的安全保护措施保护您提供的个人信息,采取物理、技术和行政管理安全措施降低丢失、误用、非授权访问、披露和更改的风险,尽力防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。<br />
          (二)本缴费系统建立专门的管理制度、流程和组织以保障信息的安全,严格限制访问信息的人员范围,要求他们遵守保密义务,并进行审计。<br />
          (三)如因不可抗力或计算机病毒感染、黑客攻击等特殊原因，导致用户信息被破坏、泄密并受到损失的，本缴费系统将采取必要措施尽力减少用户的损失。<br />
          (四)互联网环境并非百分之百安全,本缴费系统将尽力确保您的个人信息安全性。如果本缴费系统的物理、技术、或管理防护设施遭到破坏,导致发生信息被非授权访问、公开披露、篡改、或毁坏及其他个人信息泄露安全事件,本缴费系统会启动应急预案,阻止安全事件扩大,并以缴费系统公告或消息通知等形式将相关情况告知您。同时,本缴费系统还将按照监管要求,上报个人信息安全事件的处置情况。<br />
          (五)您在使用本缴费系统时,本缴费系统会通过您的手机号码、姓名、身份证号或者其他身份验证信息来识别您的身份,请您务必妥善保管上述信息。一旦您泄露了上述信息,您的账户信息可能因此泄露,并可能使您的资金安全等个人权益遭受损失。<br />
          六、用户权利<br />
          您可通过本缴费系统的相关网页查看、更新并修改提供给本缴费系统的个人信息，也可以要求本缴费系统删除该信息。<br />
          七、本《隐私保护条款》如何更新<br />
          在相关法律法规发生变化或本缴费系统服务发生变动等必要情形下,本缴费系统如需对本《隐私保护条款》做出修改,将在本缴费系统公布,公布后即生效,并取代此前相关内容。您如不同意新的修改内容,请及时与本缴费系统联系或立即停止使用本《隐私保护条款》约定的服务,如您选择继续使用,则视为您完全同意并接受新的修改内容。<br />
          八、免责<br />
          本缴费系统对缴费系统上所有由第三方提供的信息、内容和服务，不提供明示或暗示的担保。本缴费系统对使用上述信息、内容和服务所造成的任何损失不承担责任。<br />
          九、其他<br />
          (一)本《隐私保护条款》的成立、生效、履行、解释及纠纷解决,适用中华人民共和国法律；法律无明文规定的,可适用国内通行的惯例。<br />
          (二)本《隐私保护条款》无论因何种原因部分无效或不可执行,都不影响本条款其他部分的效力。<br />
          (三)本《隐私保护条款》中未尽事宜,按照法律法规和本缴费系统相关业务规则及国内通行的惯例办理。<br />
          <div class="agree-box" style="display: flex; justify-content: center">
            <el-checkbox
              v-model="ischeck"
              @click.stop="agreeEvent"
              size="mini"
              text-color="#303031"
              >我同意以上协议条款</el-checkbox
            >
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="reportInfo.title"
      :visible.sync="dialogVisible"
      width="50%"
      center
    >
      <div class="dialog-report-content" v-if="reportInfo">
        <div class="time">
          <div>发布时间：{{ reportInfo.time }}</div>
          <div>点击量：{{ reportInfo.clickTimes }}</div>
          <div>作者：{{ reportInfo.inputAuthor }}</div>
          <div>信息来源：{{ reportInfo.source }}</div>
        </div>
        <div class="content" v-html="reportInfo.content"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import router from "@/router";
import request from "@/utils/request";
export default {
  data() {
    return {
      ischeck: false,
      dialogVisibleAgreement: false,
      idCard: "",
      mobile: "",
      timing: 60,
      timer: -1,
      code: "",
      timeShow: false,
      isDisabled: false,
      verificationCode: "",
      path: "https://jfxt.cdszgdx.com/public_yzm.html",
      isIdCardPass: false,
      isMobilePass: false,
      isCodePass: false,
      isVerificationCode: false,
      reportList: [{}],
      showReport: false,
      page: 1,
      dialogVisible: false,
      reportInfo: {
        title: "",
        time: "",
        content: "",
        inputAuthor: "",
        clickTimes: "",
        source: "",
      },
      pageInfo: {
        currentPage: 1,
        pageSize: 20,
        totalCount: 1,
      },
    };
  },

  watch: {
    ischeck() {
      this.dialogVisibleAgreement = false;
    },
  },

  async created() {
    this.toH5();
    this.getData();
    this.path =
      "https://jfxt.cdszgdx.com/public_yzm.html?t=" +
      new Date().getMilliseconds();
  },
  methods: {
    toH5() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      //document.writeln("您的浏览设备为：");
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        window.location = "https://jfxt.cdszgdx.com/H5/";
        //document.writeln("phone");
      } else {
        //document.writeln("pc");
      }
    },

    async getData() {
      const { data } = await request({
        url: "https://jfxt.cdszgdx.com/public_jsonjk.html",
        method: "GET",
        params: {
          meth: "Getggxxlist",
          ClassID: "0201",
          absolutepage: this.pageInfo.currentPage,
        },
      });

      this.reportList = data.map((item) => {
        return {
          title: item.Title,
          time: item.inputdate,
          ID: item.ID,
          totalCount: item.recordcount,
        };
      });
    },

    changeEvent(currentPage) {
      this.pageInfo.currentPage = currentPage;
      this.getData();
    },

    /***
     * 请求公告信息列表
     */
    async showReportEvent() {
      this.reportList = [...this.reportList];
      console.log(this.reportList);
      this.showReport = true;
      this.pageInfo.totalCount = parseInt(this.reportList[0].totalCount);
    },

    changeCode() {
      this.path =
        "https://jfxt.cdszgdx.com/public_yzm.html?t=" +
        new Date().getMilliseconds();
    },

    checkIdCard() {
      let reg =
        /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
      this.isIdCardPass = !reg.test(this.idCard);
      console.log(this.isIdCardPass); // 错 true 对 false
    },

    checkCode() {
      let reg = /^\d{4}$/;
      this.isCodePass = !reg.test(this.code);
    },

    checkPhone() {
      let reg =
        /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
      this.isMobilePass = !reg.test(this.mobile);
    },

    checkVerificationCode() {
      let reg = /^\d{6}$/;
      this.isVerificationCode = !reg.test(this.verificationCode);
    },

    async toLogin() {
      if (this.ischeck === false) {
        this.$alert("请先同意《网上缴费综合服务平台使用协议》", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // 前端需要判断非空，利用正则表达式判断是否合乎规则
      if (this.mobile === "" || this.code === "" || this.idCard === "") {
        this.$alert("手机号验证码身份证不能为空，请重新输入", "错误", {
          confirmButtonText: "确定",
        });
        this.path =
          "https://jfxt.cdszgdx.com/public_yzm.html?t=" +
          new Date().getMilliseconds();
        return;
      }

      this.checkCode();
      this.checkIdCard();
      this.checkPhone();
      this.checkVerificationCode();

      if (this.isIdCardPass && this.mobile && this.code) {
        this.$alert("手机号、验证码或身份证输入有误，请检查", "错误", {
          confirmButtonText: "确定",
        });
        this.path =
          "https://jfxt.cdszgdx.com/public_yzm.html?t=" +
          new Date().getMilliseconds();
        return;
      }

      const { data } = await request({
        url: `https://jfxt.cdszgdx.com/public_jk.html`,
        method: "get",
        params: {
          meth: "loginchk_yzm",
          mobile: this.mobile,
          idCard: this.idCard,
          code: this.code,
          verificationCode: this.verificationCode,
        },
      });

      if (data.split("|")[0] === "0") {
        this.$alert(data.split("|")[1], "错误", {
          confirmButtonText: "确定",
        });
        this.path =
          "https://jfxt.cdszgdx.com/public_yzm.html?t=" +
          new Date().getMilliseconds();
        return;
      }

      if (data.split("|")[0] === "1") {
        this.$message({
          type: "success",
          message: data.split("|")[1],
        });
        this.$store.commit("login/setGUID", data.split("|")[2]);
        this.$store.commit("login/setName", data.split("|")[3]);
        this.$store.commit("login/setIdCardNo", data.split("|")[4]);
        this.$store.commit("login/setMobileNo", data.split("|")[5]);
        console.log(data);
        console.log("push/index");
        router.push({
          path: "/index",
        });
      }
    },

    agreeEvent() {
      this.ischeck = !this.ischeck;
    },

    /**
     * 获得手机验证码
     */
    async getVerificationCode() {
      if (this.timer >= 0) {
        return;
      }
      // 前端需要判断非空，利用正则表达式判断是否合乎规则
      if (this.mobile === "" || this.code === "" || this.idCard === "") {
        this.$alert("手机号验证码身份证不能为空，请重新输入", "错误", {
          confirmButtonText: "确定",
        });
        this.path =
          "https://jfxt.cdszgdx.com/public_yzm.html?t=" +
          new Date().getMilliseconds();
        return;
      }

      if (this.isIdCardPass && this.mobile && this.code) {
        this.$alert("手机号、验证码或身份证输入有误，请检查", "错误", {
          confirmButtonText: "确定",
        });
        this.path =
          "https://jfxt.cdszgdx.com/public_yzm.html?t=" +
          new Date().getMilliseconds();

        return;
      }

      console.log(this.code);

      // 发送请求
      const { data } = await request({
        url: `https://jfxt.cdszgdx.com/public_jk.html`,
        method: "get",
        params: {
          meth: "getdlyzm",
          mobile: this.mobile,
          idCard: this.idCard,
          code: this.code,
        },
      });
      console.log(data);

      if (data.split("|")[0] === "1") {
        this.$message({
          type: "success",
          message: data.split("|")[1],
        });
      }

      if (data.split("|")[0] === "0") {
        this.$alert(data.split("|")[1], "错误", {
          confirmButtonText: "确定",
        });
        this.path =
          "https://jfxt.cdszgdx.com/public_yzm.html?t=" +
          new Date().getMilliseconds();
        return;
      }

      this.timeShow = true;
      this.isDisabled = true;

      this.timer = setInterval(() => {
        this.timing--;
        console.log(this.timing);
        if (this.timing < 1) {
          this.timing = 60;
          this.isDisabled = false;
          this.timeShow = false;
          clearInterval(this.timer);
          this.timer = -1;
        }
      }, 1000);
    },

    async getDetail(ID) {
      const { data } = await request({
        url: "https://jfxt.cdszgdx.com/public_jsonjk.html",
        method: "GET",
        params: {
          meth: "Getggxxdetail",
          ID,
        },
      });
      this.reportInfo.title = data.Title;
      this.reportInfo.time = data.inputdate;
      this.reportInfo.content = data.Content;
      this.reportInfo.inputAuthor = data.inputname;
      this.reportInfo.source = data.Source;
      this.reportInfo.clickTimes = data.ClickTimes;

      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.logo {
  margin-left: 16%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.logo img {
  height: 62px;
}
.content-info {
  position: relative;
  width: 100%;
}

::v-deep .el-button {
  height: 38px;
  padding: 0 8px;
}
.background-img img {
  width: 100%;
  height: 80vh;
}
/* 公告 */
.info-box {
  position: absolute;
  top: 71%;
  left: 16%;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 23px;
  padding: 0 45px 0 23px;
  font-size: 14px;
  width: 38%;
}
.info-box .left-img {
  border-right: 1px solid rgba(255, 255, 255, 0.38);
  padding-right: 1em;
  flex-shrink: 0;
  cursor: pointer;
}
.info-box .left-img img {
  width: 60px;
  height: 60px;
}
.info-box .right-list {
  width: 100%;
}
.info-box .right-list ul {
  padding: 1em 0 1em 3em;
}
.info-box .right-list ul li > div {
  cursor: pointer;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-box .right-list ul li > div:hover {
  text-decoration: underline;
}
/* 公告 */
.report-box {
  position: absolute;
  top: 5%;
  left: 16%;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 23px;
  padding: 0 1.15rem 1.15rem 2.3rem;
  font-size: 14px;
  width: 40%;
}
.report-box .report-box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -1.15rem;
  padding: 1rem 0 0.7rem;
  font-weight: bold;
  font-size: 18px;
}
.report-box .report-box-top > div {
  display: flex;
  align-items: center;
}
.report-box .line {
  width: 5px;
  height: 18px;
  border-radius: 3px;
  margin-right: 10px;
  display: inline-block;
  background: #fff;
}
.report-box .report-box-top img {
  width: 1.2rem;
  height: 1.2rem;
}
.report-box ul {
  height: 60vh;
  overflow: auto;
  margin: 0 -20px;
  padding: 0 20px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

/* 隐藏滚动条 */
.report-box ul::-webkit-scrollbar {
  width: 0 !important;
}
.report-box ul li > div {
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
/* .pagination div {
  background-color: #fff;
  color: #333333;
  margin-left: 8px;
  padding: 0 15px;
  vertical-align: middle;
  border-radius: 2px;
  flex-shrink: 0;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.pagination .check {
  background: #316bed;
  color: #fff;
} */

/* 登录 */
.login-box {
  position: absolute;
  top: 2%;
  left: 62%;
  background: #ffffff;
  border-radius: 20px;
  padding: 3%;
}

.loginTopImg {
  width: 100%;
  margin-bottom: 10%;
}

.login-box .input-item {
  border-bottom: 1px solid rgba(26, 80, 178, 0.12);
  padding: 5% 0;
  position: relative;
}

.login-box .input-item .tip {
  position: absolute;
  top: 63px;
  left: 97px;
  font-size: 12px;
  color: red;
}
.login-box .input-item .title {
  width: 70px;
  text-align-last: justify;
  flex-shrink: 0;
  text-align: right;
  margin-left: 2%;
}
.login-box .input-item .time {
  margin-right: 2px;
}
.el-input__inner {
  border: none !important;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: none;
}
.input-item {
  display: flex;
  align-items: center;
}
.input-item .input-code-img {
  cursor: pointer;
  width: 98px;
  height: 40px;
  padding: 0 8px;
  background-color: #d3ecfa;
  object-fit: contain;
}
.login-box .forget-password {
  font-size: 16px;
  text-align: right;
  margin: 17px 0;
}
.login-box .login-button {
  background: #3072ed;
  border-radius: 10px;
  font-size: 18px;
  color: #fff;
  width: 100%;
  height: 47px;
}
.el-button:focus,
.el-button:hover {
  color: #fff !important;
  border-color: #3072ed !important;
  background-color: #3072ed !important;
}
.el-checkbox {
  color: #303031 !important;
}
.el-checkbox__inner {
  width: 16px !important;
  height: 16px !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #303031 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #316bed !important;
  border-color: #316bed !important;
}
.agree-box {
  margin-top: 5%;
  cursor: pointer;
}
.agree-box .agree {
  color: #316bed;
  font-size: 14px;
}
/* 弹框 */
.dialog-report-content,
.prototype-content {
  min-height: 50vh;
}
.dialog-report-content .time {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
  margin-bottom: 20px;
}

.dialog-report-content .time div {
  margin-right: 10px;
}

.dialog-report-content .time div:nth-child(4) {
  margin-right: 0;
}

.dialog-report-content .content {
  text-indent: 2rem;
  letter-spacing: 2px;
  line-height: 25px;
}

.prototype-content {
  line-height: 35px;
}

.prototype-content .content p {
  text-indent: 2rem;
}

::v-deep .el-pagination.is-background .btn-next,
::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .el-pager li {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
