import router from "@/router";

const state = {
  GUID: window.sessionStorage.getItem("GUID") || "",
  Name: window.sessionStorage.getItem("Name") || "",
  IdCardNo: window.sessionStorage.getItem("IdCardNo") || "",
  MobileNo: window.sessionStorage.getItem("MobileNo") || "",
};

const mutations = {
  /**
   * GUID
   * @param {Object} state state object
   * @param {String} GUID
   */
  setGUID: (state, GUID) => {
    state.GUID = GUID;
    window.sessionStorage.setItem("GUID", state.GUID);
  },
  /**
   * Name
   * @param {Object} state state object
   * @param {String} Name
   */
  setName: (state, Name) => {
    state.Name = Name;
    window.sessionStorage.setItem("Name", state.Name);
  },

  /**
   * IdCardNo
   * @param {Object} state state object
   * @param {String} IdCardNo
   */
  setIdCardNo: (state, IdCardNo) => {
    state.IdCardNo = IdCardNo;
    window.sessionStorage.setItem("IdCardNo", state.IdCardNo);
  },

  /**
   * MobileNo
   * @param {Object} state state object
   * @param {String} MobileNo
   */
  setMobileNo: (state, MobileNo) => {
    state.MobileNo = MobileNo;
    window.sessionStorage.setItem("MobileNo", state.MobileNo);
  },

  /**
   * GUID
   * @param {Object} state state object
   * @param {String} GUID
   */
  delGUID: (state) => {
    state.GUID = "";
    window.sessionStorage.removeItem("GUID");
  },
  /**
   * Name
   * @param {Object} state state object
   * @param {String} Name
   */
  delName: (state) => {
    state.Name = "";
    window.sessionStorage.removeItem("Name");
  },

  /**
   * IdCardNo
   * @param {Object} state state object
   * @param {String} IdCardNo
   */
  delIdCardNo: (state) => {
    state.IdCardNo = "";
    window.sessionStorage.removeItem("IdCardNo");
  },

  /**
   * MobileNo
   * @param {Object} state state object
   * @param {String} MobileNo
   */
  delMobileNo: (state) => {
    state.MobileNo = "";
    window.sessionStorage.removeItem("MobileNo");
  },
};

const getters = {
  /**
   * GUID
   * @param {Object} state state object
   * @returns GUID
   */
  getGUID: (state) => {
    return state.GUID;
  },

  /**
   * Name
   * @param {Object} state state object
   * @returns Name
   */
  getName: (state) => {
    return state.Name;
  },

  /**
   * IdCardNo
   * @param {Object} state state object
   * @returns IdCardNo
   */
  getIdCardNo: (state) => {
    return state.IdCardNo;
  },

  /**
   * MobileNo
   * @param {Object} state state object
   * @returns MobileNo
   */
  getMobileNo: (state) => {
    return state.MobileNo;
  },
};

const actions = {
  /**
   * 退出登录
   * @param {store} store store object
   */
  async logout(store) {
    store.commit("delMobileNo");
    store.commit("delIdCardNo");
    store.commit("delName");
    store.commit("delGUID");

    router.push({
      path: "/",
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
