import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: LoginView,
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../views/IndexView.vue"),
  },
  {
    path: "/isPay",
    name: "isPay",
    component: () => import("../views/isPayView.vue"),
  },

  {
    path: "/pay",
    name: "pay",
    component: () => import("../views/payView.vue"),
  },

  {
    path: "/test",
    name: "test",
    component: () => import("../views/Test.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
