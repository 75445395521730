import axios from "axios";

const request = axios.create({
  baseURL: "https://jfxt.cdszgdx.com/",
});

request.interceptors.request.use(
  (config) => {
    config.headers.Authorization =
      "Bearer d0b33fa4-a4b3-4399-b295-7bf77bba42ba";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request;
